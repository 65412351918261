// StateDirectorate.module.scss
.container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 0 1.5rem;
    font-family: 'Inter', sans-serif;
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }
  
  .title {
    color: #1a365d;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e2e8f0;
  }
  
  .infoContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .infoRow {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 2rem;
    padding: 1rem;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  
    &:hover {
      background-color: #f8fafc;
    }
  }
  
  .label {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #4a5568;
    font-weight: 500;
  
    .icon {
      width: 20px;
      height: 20px;
      color: #4299e1;
    }
  
    span {
      font-size: 0.95rem;
    }
  }
  
  .value {
    color: #2d3748;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
  }
  
  .email {
    color: #4299e1;
    text-decoration: none;
    transition: color 0.2s ease;
  
    &:hover {
      color: #2b6cb0;
      text-decoration: underline;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .card {
      padding: 1.5rem;
    }
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    .infoRow {
      grid-template-columns: 1fr;
      gap: 0.5rem;
      padding: 0.75rem;
    }
  
    .label {
      margin-bottom: 0.25rem;
    }
  
    .value {
      padding-left: 2.25rem;
    }
  }