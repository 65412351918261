.dashboard {
    min-height: 100vh;
    background-color: #f8fafc;
    padding: 24px;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header {
    margin-bottom: 32px;
    
    h1 {
      font-size: 28px;
      color: #1e293b;
      font-weight: 600;
    }
  }
  
  .dataSection {
    margin-bottom: 40px;
  }
  
  .card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .cardHeader {
    padding: 20px 24px;
    border-bottom: 1px solid #e2e8f0;
    
    h2 {
      font-size: 18px;
      color: #334155;
      font-weight: 500;
    }
  }
  
  .tableWrapper {
    padding: 0 24px;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
  
    th {
      text-align: left;
      padding: 12px 16px;
      background-color: #f8fafc;
      color: #475569;
      font-weight: 500;
      border-bottom: 2px solid #e2e8f0;
      white-space: nowrap;
  
      &:first-child {
        padding-left: 0;
      }
  
      &:last-child {
        padding-right: 0;
      }
    }
  
    td {
      padding: 12px 16px;
      color: #334155;
      border-bottom: 1px solid #e2e8f0;
  
      &:first-child {
        padding-left: 0;
        font-weight: 500;
      }
  
      &:last-child {
        padding-right: 0;
      }
    }
  
    tbody {
      tr {
        &:hover {
          background-color: #f8fafc;
        }
  
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .dashboard {
      padding: 16px;
    }
  
    .header {
      margin-bottom: 24px;
  
      h1 {
        font-size: 24px;
      }
    }
  
    .cardHeader {
      padding: 16px;
  
      h2 {
        font-size: 16px;
      }
    }
  
    .tableWrapper {
      padding: 0 16px;
    }
  
    .table {
      th, td {
        padding: 12px;
        font-size: 14px;
      }
    }
  }