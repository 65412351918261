.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
      padding: 1rem;
    }
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eaeaea;
    background: #fafafa;
  }
  
  .content {
    padding: 1.5rem;
  }
  
  .grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .row {
    display: grid;
    grid-template-columns: 250px 1fr;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    background: #f8fafc;
    transition: background-color 0.2s ease;
    
    &:hover {
      background: #f1f5f9;
    }
  }
  
  .label {
    color: #475569;
    font-weight: 500;
    padding-right: 1rem;
  }
  
  .value {
    color: #1e293b;
    font-weight: 500;
  }
  
  .valueWithAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .status {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: #22c55e;
    color: white;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: #475569;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      background: #f1f5f9;
      border-color: #cbd5e1;
    }
    
    .icon {
      color: #3b82f6;
    }
  }
  
  @media (max-width: 640px) {
    .container {
      margin: 1rem;
    }
    
    .header {
      padding: 1rem;
    }
    
    .content {
      padding: 1rem;
    }
    
    .row {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
    
    .valueWithAction {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .button {
      width: 100%;
      justify-content: center;
    }
  }