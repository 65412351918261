.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
  
  .card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .title {
    font-size: 1.75rem;
    font-weight: 600;
    color: #1a1a1a;
    padding: 1.5rem;
    margin: 0;
    border-bottom: 1px solid #eaeaea;
  }
  
  .tableWrapper {
    overflow-x: auto;
    padding: 1rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .headerCell {
    background-color: #f9fafb;
    color: #4b5563;
    font-weight: 600;
    padding: 1rem;
    border-bottom: 2px solid #e5e7eb;
    white-space: nowrap;
    
    &:first-child {
      border-top-left-radius: 8px;
    }
    
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  
  .tableRow {
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f9fafb;
    }
  }
  
  .evenRow {
    background-color: #f8fafc;
  }
  
  .tableCell {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    color: #374151;
    
    &:first-child {
      font-weight: 500;
      color: #1f2937;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
    
    .title {
      font-size: 1.5rem;
      padding: 1rem;
    }
    
    .tableCell, 
    .headerCell {
      padding: 0.75rem;
    }
  }