.container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
      padding: 1rem;
    }
  }
  
  .card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .header {
    padding: 1.5rem 2rem;
    background: #f8fafc;
    border-bottom: 1px solid #edf2f7;
  }
  
  .title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
  }
  
  .tableWrapper {
    overflow-x: auto;
    padding: 1rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px;
    
    th {
      background: #f8fafc;
      padding: 1rem;
      text-align: left;
      font-weight: 600;
      color: #4a5568;
      font-size: 0.875rem;
      border-bottom: 2px solid #edf2f7;
      white-space: nowrap;
    }
    
    td {
      padding: 1rem;
      border-bottom: 1px solid #edf2f7;
      font-size: 0.875rem;
      color: #2d3748;
    }
    
    tbody tr {
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color: #f7fafc;
      }
    }
  }
  
  .nameCell {
    font-weight: 500;
    color: #2c5282;
  }
  
  .designationCell {
    font-weight: 500;
  }
  
  .tradeCell {
    max-width: 200px;
    line-height: 1.4;
  }
  
  .employmentType {
    color: #2f855a;
    font-weight: 500;
  }
  
  .ctiStatus {
    font-weight: 500;
    color: #c53030;
  }
  
  .photoLink {
    padding: 0.5rem 1rem;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #3182ce;
    }
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 1rem;
    }
    
    .title {
      font-size: 1.25rem;
    }
    
    .table {
      th, td {
        padding: 0.75rem;
        font-size: 0.8125rem;
      }
    }
  }