.menuManagement {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 15px;
    color: #333;
    width: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
  
    h1 {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
    }
  }
  
  .newMenuButton {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 14px;
    background-color: #0275d8;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #025aa5;
    }
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .recordsPerPage {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .select {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 0.9rem;
  }
  
  .recordsLabel {
    font-size: 0.9rem;
    color: #555;
  }
  
  .search {
    display: flex;
    align-items: center;
    gap: 8px;
  
    label {
      font-size: 0.9rem;
      color: #555;
    }
  }
  
  .searchInput {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    min-width: 250px;
  }
  
  .tableContainer {
    overflow-x: auto;
    margin-bottom: 15px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  
    th, td {
      padding: 12px 15px;
      border: 1px solid #e0e0e0;
    }
  
    th {
      background-color: #f5f5f5;
      font-weight: 600;
      font-size: 0.9rem;
    }
  
    tbody tr {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
  
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  
  .serialColumn {
    width: 80px;
  }
  
  .actionColumn {
    width: 120px;
  }
  
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .editButton, .deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .editButton {
    background-color: #5bc0de;
    color: white;
  
    &:hover {
      background-color: #31b0d5;
    }
  }
  
  .deleteButton {
    background-color: #d9534f;
    color: white;
  
    &:hover {
      background-color: #c9302c;
    }
  }
  
  .separator {
    color: #ccc;
  }
  
  .pagination {
    font-size: 0.9rem;
    color: #555;
    margin-top: 10px;
  
    p {
      margin: 0;
    }
  }
  
  @media (max-width: 768px) {
    .controls {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .search {
      width: 100%;
    }
  
    .searchInput {
      flex-grow: 1;
      min-width: 0;
    }
  }
  .main{
    display: flex;
  }