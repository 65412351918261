/* ProfileSidebar.module.scss */
.sidebarContainer {
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 24px;
    margin-bottom: 20px;
  }
  
  .profileHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .avatarContainer {
    margin-right: 15px;
  }
  
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .nameSection {
    flex: 1;
  }
  
  .name {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 0 0 4px 0;
  }
  
  .department {
    font-size: 14px;
    color: #666;
    margin: 0;
  }
  
  .statsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  
  .statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  .statLabel {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  
  .statValue {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  .actionButtons {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
  }
  
  .followButton, .messageButton {
    flex: 1;
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .followButton {
    background-color: #4a6cf7;
    color: white;
    
    &:hover {
      background-color: #3a5ce5;
    }
  }
  
  .messageButton {
    background-color: #4ecdc4;
    color: white;
    
    &:hover {
      background-color: #3ebeb5;
    }
  }
  
  .sectionTitle {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0 0 16px 0;
  }
  
  .detailsList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .detailItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .detailIcon {
    margin-right: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
  }
  
  .detailContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .detailLabel {
    font-size: 14px;
    color: #666;
    margin-bottom: 2px;
  }
  
  .detailValue {
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }