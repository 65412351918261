.container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
      padding: 1rem;
    }
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eaeaea;
    background: #fafafa;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .tableWrapper {
    // overflow-x: auto;
    padding: 1rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px;
    
    th {
      text-align: left;
      padding: 1rem;
      font-weight: 600;
      color: #4a5568;
      background: #f7fafc;
      border-bottom: 2px solid #edf2f7;
      white-space: nowrap;
    }
    
    td {
      padding: 1rem;
      border-bottom: 1px solid #edf2f7;
      color: #2d3748;
    }
    
    tbody tr {
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color: #f7fafc;
      }
    }
    
    @media (max-width: 768px) {
      th, td {
        padding: 0.75rem;
      }
    }
  }