/* PhotoPanel.module.scss */
/* Variables */
:root {
    --primary-color: #2196f3;
    --primary-dark: #1976d2;
    --danger-color: #f44336;
    --text-color: #333;
    --text-light: #6e6e6e;
    --border-color: #e0e0e0;
    --bg-color: #fafafa;
    --panel-bg: #fff;
    --shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    --border-radius: 8px;
    --transition: all 0.3s ease;
  }
  
  /* Base styles */
  .container {
    font-family: 'Roboto', 'Segoe UI', sans-serif;
    color: var(--text-color);
    // max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Breadcrumb */
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .breadcrumbLink {
    color: var(--primary-color);
    text-decoration: none;
    transition: var(--transition);
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .breadcrumbSeparator {
    margin: 0 8px;
    color: var(--text-light);
  }
  
  .breadcrumbCurrent {
    color: var(--text-light);
  }
  
  /* Panel */
  .panel {
    background-color: var(--panel-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  .panelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid var(--border-color);
  }
  
  .panelTitle {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  
  .panelIcon {
    margin-right: 8px;
    display: inline-flex;
    color: var(--text-light);
  }
  
  .panelControls {
    display: flex;
    gap: 8px;
  }
  
  .iconButton {
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light);
    cursor: pointer;
    transition: var(--transition);
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: var(--text-color);
    }
  }
  
  .panelContent {
    padding: 20px;
  }
  
  /* Section Title */
  .sectionTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
  }
  
  /* Image Grid */
  .imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .imageCard {
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    transition: var(--transition);
    
    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      
      .imageOverlay {
        opacity: 1;
      }
    }
  }
  
  .imageWrapper {
    position: relative;
    aspect-ratio: 1;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: var(--transition);
  }
  
  .deleteButton {
    background-color: var(--danger-color);
    color: white;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transition);
    
    &:hover {
      background-color: darken(#f44336, 10%);
      transform: scale(1.1);
    }
  }
  
  .imageTitle {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    background-color: #f5f5f5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Upload Section */
  .uploadSection {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: var(--border-radius);
    margin-top: 20px;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formLabel {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
  }
  
  .formInput {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    transition: var(--transition);
    
    &:focus {
      border-color: var(--primary-color);
      outline: none;
      box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
    }
  }
  
  .fileInputWrapper {
    position: relative;
  }
  
  .fileInput {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  .customFileInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 42px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
  }
  
  .fileInputText {
    flex-grow: 1;
    padding: 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-light);
    font-size: 14px;
  }
  
  .browseButton {
    background-color: #e0e0e0;
    border: none;
    padding: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    font-size: 14px;
    white-space: nowrap;
    
    &:hover {
      background-color: #d5d5d5;
    }
    
    .buttonIcon {
      margin-right: 6px;
    }
  }
  
  .saveButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: var(--transition);
    
    &:hover {
      background-color: var(--primary-dark);
    }
    
    .buttonIcon {
      margin-right: 8px;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .imageGrid {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 15px;
    }
    
    .panelHeader, .panelContent {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .imageGrid {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 10px;
    }
    
    .customFileInput {
      flex-direction: column;
      height: auto;
    }
    
    .fileInputText {
      width: 100%;
      padding: 10px 12px;
      border-bottom: 1px solid var(--border-color);
    }
    
    .browseButton {
      width: 100%;
      padding: 10px 0;
      justify-content: center;
    }
  }
  .main{
    display: flex;
  }