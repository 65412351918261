.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .contentWrapper {
    display: flex;
    gap: 2rem;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .documentsSection {
    flex: 1;
  }
  
  .title {
    font-size: 1.5rem;
    color: #2c5282;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  .tableContainer {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  
    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }
  
    th {
      background: #f7fafc;
      font-weight: 600;
      color: #4a5568;
    }
  
    tr {
      &:hover {
        background-color: #f8fafc;
      }
    }
  
    a {
      color: #3182ce;
      text-decoration: none;
      
      &:hover {
        color: #2c5282;
        text-decoration: underline;
      }
    }
  }
  
  .rightContent {
    width: 300px;
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .feeStatement {
    background: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h3 {
      font-size: 1.25rem;
      color: #2c5282;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  
    img {
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
  
  .announcement {
    background: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h3 {
      font-size: 1.25rem;
      color: #2c5282;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }
  
  .announcementContent {
    p {
      margin: 0.5rem 0;
      color: #4a5568;
      text-align: center;
    }
  }
  
  .downloads {
    background: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h3 {
      font-size: 1.25rem;
      color: #2c5282;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        margin: 0.5rem 0;
  
        a {
          color: #3182ce;
          text-decoration: none;
          display: block;
          padding: 0.5rem 0;
          
          &:hover {
            color: #2c5282;
            text-decoration: underline;
          }
        }
      }
    }
  }