.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
    padding: 1.5rem;
    margin: 0;
    background: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    text-align: center;
  }
  
  .tableWrapper {
    padding: 1.5rem;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
  
    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #e9ecef;
    }
  
    th {
      font-weight: 600;
      color: #495057;
      background: #f8f9fa;
    }
  
    td {
      color: #212529;
    }
  
    tbody {
      tr {
        transition: background-color 0.2s ease;
  
        &:hover {
          background-color: #f8f9fa;
        }
  
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      padding: 1rem;
      font-size: 1.25rem;
    }
  
    .tableWrapper {
      padding: 1rem;
    }
  
    .table {
      th, td {
        padding: 0.75rem;
      }
    }
  }