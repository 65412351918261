.sliderContainer {
    width: 90%;
    margin: auto;
    max-width: 900px;
  }
  
  .image {
    width: 100%;
    height: 30vw;
    border-radius: 10px;
  }
  .hero {
    background-color: #f3f4f6;
 

    &Container {
      max-width: 100%;


      margin: 0 auto;
      padding: 3rem 1rem;
    }
  
    &Grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }