.container {
    text-align: center;
    padding: 2rem;
  }
  
  .heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .highlight {
    color: #00a9e0;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    padding: 20px;
  }
  
  .imageWrapper {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .image {
    width: 100%;
    height: 10vw;
    display: block;
    border-radius: 10px;
  }