.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2rem;
    color: #2d3748;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  
  .tableWrapper {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .tableHeader {
    background: #f7fafc;
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
    
    h2 {
      font-size: 1.25rem;
      color: #2d3748;
      margin: 0;
      text-align: center;
      font-weight: 600;
    }
  }
  
  .tableContainer {
    overflow-x: auto;
    padding: 1rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  
    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }
  
    th {
      background: #f7fafc;
      font-weight: 600;
      color: #4a5568;
      white-space: nowrap;
    }
  
    td {
      color: #2d3748;
    }
  
    tbody {
      tr {
        &:hover {
          background-color: #f8fafc;
        }
  
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  // Responsive styles
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    .tableHeader {
      h2 {
        font-size: 1.1rem;
      }
    }
  
    .tableContainer {
      padding: 0.5rem;
    }
  
    .table {
      th, td {
        padding: 0.75rem;
        font-size: 0.875rem;
      }
    }
  }
  
  // Print styles
  @media print {
    .container {
      padding: 0;
    }
  
    .tableWrapper {
      box-shadow: none;
    }
  
    .table {
      th, td {
        border: 1px solid #e2e8f0;
      }
    }
  }