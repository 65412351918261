.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
      padding: 1rem;
    }
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eaeaea;
    background: #fafafa;
  }
  
  .title {
    font-size: 1.75rem;
    font-weight: 600;
    color: #2c3e50;
    padding: 1.5rem;
    margin: 0;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
    border-bottom: 1px solid #dee2e6;
  }
  
  .tableWrapper {
    overflow-x: auto;
    padding: 1rem;
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #cbd5e1;
      border-radius: 4px;
      
      &:hover {
        background: #94a3b8;
      }
    }
  }
  
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
  }
  
  .headerCell {
    background-color: #f8fafc;
    color: #334155;
    font-weight: 600;
    padding: 1rem;
    border-bottom: 2px solid #e2e8f0;
    white-space: nowrap;
    position: sticky;
    top: 0;
    
    &:first-child {
      border-top-left-radius: 8px;
    }
    
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  
  .tableRow {
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #f8fafc;
    }
    
    &:last-child {
      .tableCell {
        border-bottom: none;
      }
    }
  }
  
  .tableCell {
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
    color: #475569;
    
    &:first-child {
      font-weight: 500;
      color: #334155;
    }
  }
  
  @media (max-width: 1024px) {
    .container {
      padding: 1rem;
    }
    
    .title {
      font-size: 1.5rem;
      padding: 1.25rem;
    }
    
    .tableCell, 
    .headerCell {
      padding: 0.875rem;
      font-size: 0.9375rem;
    }
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 1.25rem;
      padding: 1rem;
    }
    
    .tableCell, 
    .headerCell {
      padding: 0.75rem;
      font-size: 0.875rem;
    }
  }