.container {
    max-width: 1400px;
    margin: 2rem auto;
    padding: 0 1.5rem;
    font-family: 'Inter', sans-serif;
  }
  
  .mainTitle {
    color: #2c5282;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e2e8f0;
  }
  
  .trustSection {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .infoRow {
    display: flex;
    border-bottom: 1px solid #edf2f7;
    padding: 1rem 0;
  
    &:last-child {
      border-bottom: none;
    }
  }
  
  .label {
    flex: 0 0 250px;
    font-weight: 500;
    color: #4a5568;
  }
  
  .value {
    flex: 1;
    color: #2d3748;
  }
  
  .membersSection {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    padding: 2rem;
  }
  
  .sectionTitle {
    color: #2c5282;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .tableWrapper {
    overflow-x: auto;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px;
  
    th {
      background: #f7fafc;
      color: #4a5568;
      font-weight: 600;
      text-align: left;
      padding: 1rem;
      font-size: 0.875rem;
      border-bottom: 2px solid #e2e8f0;
    }
  
    td {
      padding: 1rem;
      color: #4a5568;
      border-bottom: 1px solid #e2e8f0;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  
    tbody tr {
      &:hover {
        background-color: #f7fafc;
      }
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .mainTitle {
      font-size: 1.5rem;
    }
  
    .trustSection,
    .membersSection {
      padding: 1rem;
    }
  
    .infoRow {
      flex-direction: column;
      padding: 0.75rem 0;
    }
  
    .label {
      flex: 0 0 auto;
      margin-bottom: 0.25rem;
    }
  }