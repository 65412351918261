.container {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width:100% ;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  
    th, td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }
  
    th {
      background: #f8f9fa;
      font-weight: bold;
    }
  }
  
  .title {
    color: #007bff;
    cursor: pointer;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .deleteButton {
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
  
    &:hover {
      background: #c82333;
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .pageButton {
    padding: 6px 12px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  
    &:hover {
      background: #0056b3;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .input,
  .fileInput {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  .saveButton {
    padding: 10px 16px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  
    &:hover {
      background: #218838;
    }
  }
.main{
    display: flex;
}  