.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #eaeaea;
}

.headerIcon {
  color: #ffffff;
}

.title {
  // color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.tableWrapper {
  padding: 1.5rem;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
    
    &:hover {
      background: #94a3b8;
    }
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 600px;
}

.th {
  background: #f8fafc;
  color: #475569;
  font-weight: 600;
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid #e2e8f0;
  
  &:first-child {
    border-top-left-radius: 8px;
  }
  
  &:last-child {
    border-top-right-radius: 8px;
  }
}

.tr {
  &:hover {
    background-color: #f8fafc;
  }
}

.td {
  padding: 1rem;
  color: #334155;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.2s ease;
}

.itemName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.itemIcon {
  color: #0ea5e9;
}

.quantity {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f0f9ff;
  color: #0ea5e9;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  min-width: 2.5rem;
}

@media (max-width: 768px) {
  .container {
    margin: 1rem;
    border-radius: 8px;
  }
  
  .header {
    padding: 1rem;
  }
  
  .title {
    font-size: 1.25rem;
  }
  
  .tableWrapper {
    padding: 1rem;
  }
  
  .th, 
  .td {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
  
  .quantity {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}