.locationWrapper {
    background-color: #1a1a1a;
    color: #fff;
    padding: 3rem 0;
  }
  
  .mainWrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
  
  .contentLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .mapBlock {
    &Header {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      font-weight: 500;
      color: #e0e0e0;
    }
  
    &Frame {
      background-color: #252525;
      border-radius: 8px;
      overflow: hidden;
      height: 400px;
      position: relative;
    }
  
    &Buttons {
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: flex;
      gap: 0.5rem;
      z-index: 10;
  
      &Control {
        background-color: white;
        border: none;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.2s;
  
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  
  .infoBlock {
    &Header {
      font-size: 2rem;
      margin-bottom: 2rem;
      font-weight: 600;
      color: #fff;
    }
  
    &List {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  
    &Row {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
  
      &Icon {
        color: #00bcd4;
        flex-shrink: 0;
        margin-top: 0.25rem;
      }
  
      &Data {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
  
      &Label {
        color: #00bcd4;
        font-size: 0.875rem;
      }
  
      &Value {
        color: #e0e0e0;
        line-height: 1.5;
      }
    }
  }
  
  .bottomBar {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &Caption {
      color: #808080;
      font-size: 0.875rem;
    }
    
    &Credit {
      color: #00bcd4;
      text-decoration: none;
      font-size: 0.875rem;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }