.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: system-ui, -apple-system, sans-serif;
  }
  
  .mainContent {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    margin-top: 2rem;
  
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .welcome {
    text-align: center;
    margin-bottom: 3rem;
  
    &Title {
      font-size: 2.5rem;
      margin-bottom: 1rem;
  
      span {
        color: #00bcd4;
      }
    }
  }
  
  .introduction {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  
    &Title {
      color: #2c3e50;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  
    &Text {
      color: #546e7a;
      line-height: 1.6;
      margin-bottom: 1rem;
    }
  }
  
  .aboutSection {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
    &Title {
      font-size: 1.5rem;
      color: #2c3e50;
      padding: 1rem 1.5rem;
      background-color: #f8f9fa;
      border-bottom: 1px solid #e9ecef;
    }
  
    &Table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #e9ecef;
        text-align: left;
      }
  
      th {
        color: #2c3e50;
        font-weight: 600;
        width: 40%;
      }
  
      td {
        color: #546e7a;
      }
    }
  }
  
  .sidebar {
    &Section {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
    }
  
    &Title {
      font-size: 1.25rem;
      color: #fff;
      background-color: #2c3e50;
      padding: 1rem 1.5rem;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  
    &Content {
      padding: 1rem 1.5rem;
    }
  
    &List {
      list-style: none;
      padding: 0;
      margin: 0;
  
      li {
        padding: 0.75rem 0;
        border-bottom: 1px solid #e9ecef;
  
        &:last-child {
          border-bottom: none;
        }
  
        a {
          color: #546e7a;
          text-decoration: none;
          display: flex;
          align-items: center;
          transition: color 0.2s;
  
          &:hover {
            color: #00bcd4;
          }
  
          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  
  .feeStatement {
    position: relative;
    background-color: #4caf50;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  
 