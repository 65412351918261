.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Inter', sans-serif;
  }
  
  .section {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .title {
    color: #1a1a1a;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #f0f0f0;
  }
  
  .tableWrapper {
    overflow-x: auto;
    margin: 1rem 0;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
    background: #ffffff;
  
    th {
      background: #f8f9fa;
      color: #4a5568;
      font-weight: 600;
      text-align: left;
      padding: 1rem;
      font-size: 0.875rem;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      border-bottom: 2px solid #e0e0e0;
    }
  
    td {
      padding: 1rem;
      color: #2d3748;
      border-bottom: 1px solid #e0e0e0;
      font-size: 0.9375rem;
      line-height: 1.5;
      
      &:first-child {
        font-weight: 500;
      }
    }
  
    tbody tr {
      &:hover {
        background-color: #f8fafc;
      }
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .section {
      padding: 1rem;
    }
  
    .title {
      font-size: 1.25rem;
    }
  
    .table {
      th, td {
        padding: 0.75rem;
        font-size: 0.875rem;
      }
    }
  }