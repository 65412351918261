// Variables
$primary-color: #ff5722;
$text-color: #333;
$background-light: #f9f9f9;
$border-color: #ddd;

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 1vw;
}

.heading {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: $text-color;
  margin-bottom: 20px;
  position: relative;
  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: $primary-color;
    display: block;
    margin: 8px auto 0;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.formWrapper, .infoWrapper {
  flex: 1;
}

.subHeading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: $text-color;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  
  label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    span {
      color: $primary-color;
    }
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: 5px;
    font-size: 14px;
    
    &:focus {
      border-color: $primary-color;
      outline: none;
      box-shadow: 0px 0px 4px rgba(255, 87, 34, 0.5);
    }
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
}

.submitButton {
  background: $primary-color;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background: darken($primary-color, 10%);
  }
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: $text-color;
  margin: 10px 0;

  svg {
    color: $primary-color;
  }
}
