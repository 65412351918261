.container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2rem;
    color: #2d3748;
    margin-bottom: 2rem;
    font-weight: 600;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 60px;
      height: 3px;
      background: #4299e1;
    }
  }
  
  .tableContainer {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .tableWrapper {
    overflow-x: auto;
    margin: 1rem;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1000px;
    
    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }
  
    th {
      background: #f7fafc;
      color: #2d3748;
      font-weight: 600;
      white-space: nowrap;
      position: sticky;
      top: 0;
      
      &:first-child {
        width: 80px;
      }
    }
  
    td {
      color: #4a5568;
      
      &:nth-child(3) {
        font-weight: 500;
      }
    }
  
    tbody {
      tr {
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: #f8fafc;
        }
  
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  // Status badge styles
  .status {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    display: inline-block;
  }
  
  // Responsive styles
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    .tableContainer {
      border-radius: 8px;
    }
  
    .tableWrapper {
      margin: 0.5rem;
    }
  
    .table {
      th, td {
        padding: 0.75rem;
        font-size: 0.875rem;
      }
    }
  }
  
  // Print styles
  @media print {
    .container {
      padding: 0;
    }
  
    .tableContainer {
      box-shadow: none;
    }
  
    .table {
      th, td {
        border: 1px solid #e2e8f0;
      }
    }
  }