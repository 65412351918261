.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #b3e0ff;
    background: linear-gradient(to bottom, #b3e0ff, #87ceeb);
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .loginCard {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    
    @media (max-width: 480px) {
      padding: 20px;
    }
  }
  
  .logoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .logo {
    max-width: 250px;
    height: auto;
  }
  
  .loginHeader {
    text-align: center;
    background-color: #e9f5fc;
    color: #4a89a4;
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  
  .inputGroup {
    position: relative;
    margin-bottom: 15px;
  }
  
  .inputIcon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #777;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inputField {
    width: 87%;
    padding: 12px 12px 12px 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 15px;
    transition: border-color 0.2s ease;
    
    &:focus {
      border-color: #0078d4;
      outline: none;
    }
  }
  
  .captchaGroup {
    display: flex;
    margin-bottom: 20px;
  }
  
  .captchaQuestion {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 0 15px;
    font-size: 15px;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 4px 0 0 4px;
    min-width: 80px;
  }
  
  .captchaInput {
    flex: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 0 4px 4px 0;
    font-size: 15px;
    
    &:focus {
      border-color: #0078d4;
      outline: none;
    }
  }
  
  .loginButton {
    width: 100%;
    padding: 12px;
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: #0069ba;
    }
    
    &:focus {
      outline: none;
    }
  }
  
  @media (max-width: 768px) {
    .loginCard {
      max-width: 100%;
    }
  }