.container {
    // min-height: 100vh;
    background-color: #f9fafb;
  }
  
  .topBar {
    background-color: #2c3e50;
    color: white;
    padding: 0.5rem 1rem;
  
    &Container {
      max-width: 80rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    &Contact {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      &Item {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
  
        svg {
          margin-right: 0.5rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  
  .header {
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  
    &Container {
      max-width: 80rem;
      margin: 0 auto;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    &Logo {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      &Title {
        font-size: 1.5rem;
        font-weight: 700;
        color: #1e3a8a;
      }
  
      &Subtitle {
        font-size: 1.125rem;
        color: #4b5563;
      }
    }
  
    &Image {
      height: 3rem;
    }
  }
  
  .nav {
    background-color:#2c3e50;
    color: white;
  
    &Container {
      max-width: 80rem;
      margin: 0 auto;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
    }
  
    &List {
      display: flex;
      gap: 2rem;
    }
  
    &Item {
      padding: 1rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 500;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #1e3a8a;
      }
    }
  }
  

  
  .card {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    width: 60vw;
    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    &Image {
      aspect-ratio: 18 / 9;
      width: 100%;
      object-fit: cover;
    }
  
    &Content {
      padding: 1rem;
    }
  
    &Title {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1f2937;
      margin-bottom: 0.5rem;
    }
  
    &Description {
      font-size: 0.875rem;
      color: #4b5563;
    }
  }

  .nav {
    background-color:#2c3e50;
    color: white;
    position: relative;
  
    &Container {
      max-width: 80rem;
      margin: 0 auto;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
    }
  
    &List {
      display: flex;
      gap: 1rem;
    }
  
    &Item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 1rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #1e3a8a;
      }
  
      svg {
        width: 1rem;
        height: 1rem;
        margin-left: 0.25rem;
      }
    }
  
    &Dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 240px;
      background-color: white;
      border-radius: 0.375rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: all 0.2s;
      z-index: 50;
  
      &Visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
  
      &Item {
        display: block;
        padding: 0.75rem 1rem;
        color: #1f2937;
        font-size: 0.875rem;
        transition: all 0.2s;
  
        &:hover {
          background-color: #f3f4f6;
          color: #1e40af;
        }
  
        &:first-child {
          border-top-left-radius: 0.375rem;
          border-top-right-radius: 0.375rem;
        }
  
        &:last-child {
          border-bottom-left-radius: 0.375rem;
          border-bottom-right-radius: 0.375rem;
        }
      }
    }
  }

  .navItem {
    border-bottom: none;
    color: white;
    text-decoration: none;
  }
  .navLink{
    border-bottom: none;
    color: white;
    text-decoration: none;
    display: flex;
    align-items:center;
  }
