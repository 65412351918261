// SiteOptionEditPanel.module.scss
$panel-bg: #ffffff;
$panel-border: #e0e0e0;
$primary-color: #2196f3;
$hover-color: #e3f2fd;
$text-color: #333333;
$light-text: #757575;
$shadow-color: rgba(0, 0, 0, 0.1);
$error-color: #f44336;
$success-color: #4caf50;

.editPanelContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: $panel-bg;
  border-radius: 4px;
  box-shadow: 0 2px 10px $shadow-color;
  overflow: hidden;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.panelHeader {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid $panel-border;
  cursor: pointer;
  user-select: none;
}

.expandIcon {
  font-size: 18px;
  margin-right: 8px;
  color: $primary-color;
  font-weight: bold;
}

.panelTitle {
  flex: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: $text-color;
}

.headerControls {
  display: flex;
  gap: 4px;
}

.headerButton {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  
  &:hover {
    background-color: $hover-color;
  }
}

.editForm {
  padding: 16px 0;
}

.formSection {
  display: grid;
  grid-template-columns: 200px 1fr 300px;
  border-bottom: 1px solid $panel-border;
  
  &:last-child {
    border-bottom: none;
  }
}

.sectionLabel {
  padding: 16px;
  font-weight: 500;
  color: $text-color;
}

.sectionContent {
  padding: 16px;
  border-left: 1px solid $panel-border;
  border-right: 1px solid $panel-border;
}

.sectionHelp {
  padding: 16px;
  color: $primary-color;
  font-size: 14px;
}

.inputGroup {
  display: flex;
  margin-bottom: 12px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.inputField {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid $panel-border;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }
}

.textareaField {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid $panel-border;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }
}

.inputLabel {
  width: 120px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  color: $text-color;
  font-size: 14px;
}

.fileUpload {
  display: flex;
  align-items: center;
}

.fileName {
  flex: 1;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border: 1px solid $panel-border;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  color: $light-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileButton {
  padding: 8px 12px;
  background-color: #e0e0e0;
  border: 1px solid $panel-border;
  border-left: none;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #d0d0d0;
  }
}

.hiddenFileInput {
  display: none;
}

.saveButton {
  padding: 10px 16px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .formSection {
    grid-template-columns: 1fr;
  }
  
  .sectionContent, .sectionHelp {
    border-left: none;
    border-right: none;
  }
  
  .sectionHelp {
    color: $light-text;
    padding-top: 0;
  }
}
.main{
    display: flex;
}